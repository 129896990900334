<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div>
        <div *ngIf="is_loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <main class="tiles padding">
            <a routerLink="/news" class="link-clear" *ngIf="is_logged_in">
                <app-icon-tile title="News" icon="fas fa-users"
                               [customClass]="'bg-card bg-news'"></app-icon-tile>
            </a>
            <!--
            <a routerLink="/upcoming-events" class="link-clear">
                <app-icon-tile title="Events" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-guides'"></app-icon-tile>
            </a>
            -->
            <a routerLink="/guides" class="link-clear" *ngIf="is_logged_in">
                <app-icon-tile title="Guides" icon="fas fa-users"
                               [customClass]="'bg-card bg-guides'"></app-icon-tile>
            </a>

            <a routerLink="/bot" class="link-clear">
                <app-icon-tile title="Bot" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-bot-events'"></app-icon-tile>
            </a>

            <a routerLink="/mining-loadouts-calc" class="link-clear">
                <app-icon-tile title="Mining Loadouts" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-bot-mining-loadouts'"></app-icon-tile>
            </a>

            <a routerLink="/rally" class="link-clear" *ngIf="is_logged_in">
                <app-icon-tile title="Rally" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-race'"></app-icon-tile>
            </a>

            <a routerLink="/points-of-interest" class="link-clear" *ngIf="is_logged_in && is_ifa">
                <app-icon-tile title="Points of Interest" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-exploration'"></app-icon-tile>
            </a>

            <a routerLink="/members" class="link-clear" *ngIf="is_logged_in && is_ifa">
                <app-icon-tile title="Members" icon="fas fa-users"
                               [customClass]="'bg-card bg-members'"></app-icon-tile>
            </a>

            <a routerLink="/fleet" class="link-clear" *ngIf="is_logged_in && is_ifa">
                <app-icon-tile title="Fleet" icon="fas fa-users"
                               [customClass]="'bg-card bg-fleet'"></app-icon-tile>
            </a>

            <a routerLink="/sc-contracts" class="link-clear" *ngIf="is_logged_in && is_ifa">
                <app-icon-tile title="Contracts" icon="fas fa-users"
                               [customClass]="'bg-card bg-contracts'"></app-icon-tile>
            </a>
            <a routerLink="/sc-commodity-inventory" class="link-clear" *ngIf="is_logged_in && is_contract_admin">
                <app-icon-tile title="Inventory" icon="fas fa-users"
                               [customClass]="'bg-card bg-inventory'"></app-icon-tile>
            </a>

            <a routerLink="/members-points" class="link-clear" *ngIf="is_logged_in && is_ifa">
                <app-icon-tile title="Members Coins" icon="fas fa-users"
                               [customClass]="'bg-card bg-members-points'"></app-icon-tile>
            </a>

            <a routerLink="/admin" class="link-clear" *ngIf="is_admin || is_contract_admin">
                <app-icon-tile title="Admin" icon="fas fa-users-cog"
                               [customClass]="'bg-card bg-admin'"></app-icon-tile>
            </a>
            <!--
            <ng-container *ngFor="let group of menu">
                <ng-container *ngFor="let entry of group.entries">
                    <a [routerLink]="entry.route" class="link-clear" *ngIf="!entry.open_new">
                        <app-icon-tile [title]="entry.name" [icon]="entry.icon"></app-icon-tile>
                    </a>
                    <a [href]="entry.route" class="link-clear" *ngIf="entry.open_new"
                        target="_blank">
                        <app-icon-tile [title]="entry.name" [icon]="entry.icon"></app-icon-tile>
                    </a>
                </ng-container>
            </ng-container>
            -->
        </main>
    </div>
<!--    <div id="viewer_container"></div>-->
</ng-template>

