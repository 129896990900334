<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="card card-1 padding table-wrapper" *ngIf="ScbMembersPointsCoins && storeExtObj">
        <app-fb-forms-table [storeObj]="ScbMembersPointsCoins"
                            [edit_allowed]="editAllowed"
                            [storeExtObj]="storeExtObj"
                            (add_click)="addRow()"
                            (row_click)="rowSelected($event)"
                            [require_filter]="require_filter"
                            [stateKey]="'members-points-details-coins'"
                            [show_add]="editAllowed"
                            [show_delete]="editAllowed"
                            [show_select]="editAllowed"
                            [title]="'Member Coins'"
        >
        </app-fb-forms-table>
    </div>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
