import { Component } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';
import {forEach} from 'lodash';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent {
  public markdown = '## Members';
  public records: any;
  public branches = [];

  constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
    const NewsStore = this.storeService.getStore('sc_user_ifa');
    NewsStore.loadStore('').then((members) => {
      this.branches = [
        {
          name: 'APOLLON',
          // Leader Apollon: 1153660464836919408
          leader: this.getMembersByRole(members, '1153660464836919408'),
          squads: [
            {
              name: 'Exploration',
              badge_key: 'exploration',
              leader: this.getMembersByRole(members, '1007717899399610398'),
              coLeader: this.getMembersByRole(members, '1008107774053453864'),
              members: this.getMembersByRole(members, '1007718844757983364', ['1007717899399610398', '1008107774053453864']),
            },
            {
              name: 'Science',
              badge_key: 'science',
              leader: this.getMembersByRole(members, '1007717899399610398'),
              coLeader: this.getMembersByRole(members, '1008108000638156931'),
              members: this.getMembersByRole(members, '1008129316841865257', ['1007717899399610398', '1008108000638156931']),
            },
            {
              name: 'Medical',
              badge_key: 'medical',
              leader: this.getMembersByRole(members, '1112831371098988564'),
              coLeader: this.getMembersByRole(members, '1039826765025124383'),
              members: this.getMembersByRole(members, '1020394086643028018', ['1112831371098988564', '1039826765025124383']),
            }
          ]
        },
        {
          name: 'ASPIS',
          // Leader Aspis: 1007712988427468800
          leader: this.getMembersByRole(members, '1007712988427468800'),
          squads: [
            {
              name: 'Defence FPS',
              badge_key: 'defence_fps',
              leader: this.getMembersByRole(members, '1007712988427468800'),
              coLeader: this.getMembersByRole(members, '1008106598188720252'),
              members: this.getMembersByRole(members, '1007722996519145512', ['1007712988427468800', '1008106598188720252']),
            },
            {
              name: 'Defence Flight',
              badge_key: 'defence_flight',
              leader: this.getMembersByRole(members, '1112344573075783732'),
              coLeader: this.getMembersByRole(members, '1008106945527427214'),
              members: this.getMembersByRole(members, '1007720001408602123', ['1112344573075783732', '1008106945527427214']),
            }
          ]
        },
        {
          name: 'EMPOROS',
          // Leader Emporos: 1153658957727014912
          leader: this.getMembersByRole(members, '1153658957727014912'),
          squads: [
            {
              name: 'Industries',
              badge_key: 'industries',
              leader: this.getMembersByRole(members, '1007716424753623170'),
              coLeader: this.getMembersByRole(members, '1008107257671729263'),
              members: this.getMembersByRole(members, '1007718477886402610', ['1007716424753623170', '1008107257671729263']),
            },
            {
              name: 'Logistics',
              badge_key: 'logistics',
              leader: this.getMembersByRole(members, '1153656494059954206'),
              coLeader: this.getMembersByRole(members, '1008107571367915540'),
              members: this.getMembersByRole(members, '1007718764067963002', ['1153656494059954206', '1008107571367915540']),
            },
            {
              name: 'Engineering',
              badge_key: 'Engineering',
              leader: this.getMembersByRole(members, '1219050353459200133'),
              coLeader: this.getMembersByRole(members, '1219051599142588579'),
              members: this.getMembersByRole(members, '1219053503859658845', ['1219050353459200133', '1219051599142588579']),
            }
          ]
        }
      ];
      this.records = members;
    });
  }

  private getMembersByRole(records: any, role: string, exclude_list: any[] = []) {

    let list_of_excluded_leader = [];
    let list_of_excluded_co_leader = [];

    if (exclude_list.length > 0) {
      list_of_excluded_leader = this.getMembersByRole(records, exclude_list[0]);
      list_of_excluded_co_leader = this.getMembersByRole(records, exclude_list[1]);
    }

    return records.filter((member: any) => {
      for (let i = 0; i < member.data.roles.length; i++) {
        if (String(member.data.roles[i]) === String(role)) {

          if (exclude_list.length > 0) {
            if (list_of_excluded_leader[0] && list_of_excluded_leader[0].data.user_id === member.data.user_id) {
              return false;
            }
            if (list_of_excluded_co_leader[0] && list_of_excluded_co_leader[0].data.user_id === member.data.user_id) {
              return false;
            }
          }

          // show only member that are not in exclude list
          return true;

        }
      }
      return false;
    });
  }

  public getBadgeForBranchLeader(branch: any, member: any) {
    // return first possible badge
    for (let i = 0; i < branch.squads.length; i++) {
      const squad = branch.squads[i];
      if (member.data['file_id_' + squad.badge_key]) {
        return squad.badge_key;
      }
    }
    return 'any';
  }
}
