/**
 * put here global configs and copy and rename it to "AppSettings.ts" to be used by project
 *
 * @author René PellenzAppSettings.Sample.ts
 */

export class AppSettings {

    // public static API_WEB_URL = 'https://rpellenz.de/star-citizen/api/public/';
    // public static sub_path = '/star-citizen';
    public static API_WEB_URL = 'https://ifa-sc.org/api/public/';
    public static sub_path = '';
    // public static API_WEB_URL = 'https://localhost/ifa-2023/dc_login-api/public/';
    // public static sub_path = '';
    public static demo_mode = false;
    // public static mqtt_host = '85.215.117.152';
    public static mqtt_host = 'wss-sc-bot.rpellenz.de';
    // public static mqtt_host = 'df4895e.online-server.cloud';
    public static mqtt_port = 9001;
    public static version = '1.1.36';

    // ids for user groups that represent our repository
    public static group_admin = 'Admin';
    public static group_member = 'Member';
    public static group_page_admin = 'Page Admin';
    public static group_event_admin = 'Event Admin';
    public static group_rally_admin = 'Rennleitung';
    public static group_contract_admin = 'Ratsmitglieder';
    public static ifa_guild_id = '1007388199032733716'; // 1007388199032733716 // fbDev 1122474256656257055
    public static group_ifa = 'Citizen';
    public static inventory_player = '364775733132001300';

    public static getRealAPILink() {
        if (window.location.hostname === 'localhost') {
            return 'http://localhost/api/public/';
        }
        return this.API_WEB_URL;
    }

}
