import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {Store} from '../../../../../class/store';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-bot-event-summary',
    templateUrl: './bot-event-summary.component.html',
    styleUrls: ['./bot-event-summary.component.css']
})
export class BotEventSummaryComponent implements OnDestroy, AfterViewInit {

    @Input()
    public event_id = 0;

    private SummaryStore: Store;
    private CommoditiesStore: Store;

    public record: any[];
    public summaryRows = [];
    private subs: Array<Subscription> = [];
    public com_summary: any[];
    public member_com_summary: {sc_username: string, rows: any[]}[] = [];

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private _mqttService: MqttService) {

    }

    ngAfterViewInit(): void {
        this.SummaryStore = this.storeService.createNoneGlobalStore('event-summary',
            'games/starcitizen/events/' + String(this.event_id) + '/summary', 'sum_profit');
        this.SummaryStore.loadStore('?no_limit=1').then((records: any[]) => {
            this.record = records[0];
            this.summaryToArray(records[0].data.user_summary);
        });

        this.CommoditiesStore = this.storeService.createNoneGlobalStore('event-cargo-summary',
            'games/starcitizen/events/' + String(this.event_id) + '/commodities_summary', 'sum_cargo_profit');
        this.CommoditiesStore.loadStore('?no_limit=1').then((records: any[]) => {
            // this.summaryToArray(records[0].data.user_summary);
            console.log('com_sum', records);
            this.com_summary = records;
        });

        this.CommoditiesStore = this.storeService.createNoneGlobalStore('event-member-cargo-summary',
            'games/starcitizen/events/' + String(this.event_id) + '/commodities_member_summary', 'sum_member_cargo_profit');
        this.CommoditiesStore.loadStore('?no_limit=1').then((records: any[]) => {
            // this.summaryToArray(records[0].data.user_summary);
            console.log('com_sum', records);
            const rows: any[] = [];

            if (records?.length) {
                let groupedRows: any[] = [];
                let currentUser = records[0].data.sc_username;

                for (const record of records) {
                    const username = record.data.sc_username;

                    if (username !== currentUser) {
                        rows.push({ sc_username: currentUser, rows: groupedRows });
                        groupedRows = [];
                        currentUser = username;
                    }

                    groupedRows.push(record);
                }

                // Push the last group
                rows.push({ sc_username: currentUser, rows: groupedRows });
            }

            this.member_com_summary = rows;
        });


        this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
            const info = JSON.parse(message.payload.toString());
            if (Number(info.event_id) === Number(this.event_id)) {
                this.SummaryStore.loadStore('').then((records: any[]) => {
                    this.record = records[0];
                    this.summaryToArray(records[0].data.user_summary);
                });

                this.CommoditiesStore.loadStore('').then((records: any[]) => {
                    // this.summaryToArray(records[0].data.user_summary);
                    this.com_summary = records;
                });
            }
        }));
    }

    private summaryToArray(records: any) {
        const rows = [];
        for (const [key, value] of Object.entries(records)) {
            rows.push(value);
        }
        this.summaryRows = rows;
    }

    public getRowColor(row: any) {
        if (row.payment > 0) {
            return '#ffab00';
        }
        return '#00c853';
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    addAsCargoSellPlaner(rows: any) {
        const payload = {
            filter: {
                max_locations: 5,
                sort_price: 'price_sell',
                max_sell_locations: 2,
                systems: ['Stanton', 'Pyro']
            },
            selected_rows: [
            ]
        };

        console.log('rows', rows);
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            console.log('row', row);
            payload.selected_rows.push(
                {
                    com_id: Number(row.data['com_id']),
                    biggest_container: 1,
                    amount: row.data['cargo_amount']
                }
            );
        }

        const jsonString = JSON.stringify(payload);
        // const encoded = btoa(jsonString);
        //
        // console.log(encoded);

        const session_key = 'cargo-sell-planer';

        localStorage.setItem(session_key, jsonString);

        this.router.navigateByUrl('bot/cargo-sell-planer');
    }
}
