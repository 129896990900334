import {FbFormObject} from '../class/fb_form_object';

export class ScbMembersPointsSummary extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'user_id', label: 'User', visible: true, noclick: false, disable_ripple: true},
            {id: 'points', label: 'Coins', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Coins',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select']
                    },
                    {
                        type: 'number',
                        name: 'coins',
                        disabled: false
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_member_points'] = this.storeService.createNoneGlobalStore('members-points-summary',
                'games/starcitizen/points/summary', 'user_id');
            this.stores['sc_user_ifa'] = this.storeService.getStore('sc_user_ifa');
            this.main_store = this.stores['sc_member_points'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['sc_member_points'].loadStore(this.filter));
            this.stores['sc_user'] = this.storeService.getStore('sc_user');
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        const dt = new Date();
        return {
            data: {
                user_id: 0,
                points: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }
    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }
}
