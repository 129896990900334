import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '../../../../../class/store';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {ScbMembersPointsCoins} from '../../../../../forms/scb-members-points-coins';
import {FbUtils} from '../../../../../class/fb-utils';
import {AppSettings} from '../../../../../config/AppSettings';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-bot-event-points',
  templateUrl: './bot-event-points.component.html',
  styleUrls: ['./bot-event-points.component.css']
})
export class BotEventPointsComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public Store: FbFormObject;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];

  @Input()
  public event_id = 0;
  @Input()
  public editAllowed = false;
  @Input()
  public event_type = '';

  private _select_caption_tpl: ElementRef;

  public totalRecords = 0;

  public column_definition: any[];
  public is_admin: boolean;

  private channelStore: Store;
  public ext_obj = {};
  public require_filter = {index: 0, filter: []};

  @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog,
              private _mqttService: MqttService,
              public http: HttpClient) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin();

    this.require_filter.filter = [];
    this.require_filter.filter.push('filter[event_id][0][mode]=equals');
    this.require_filter.filter.push('filter[event_id][0][operator]=and');
    this.require_filter.filter.push('filter[event_id][0][value]=' + String(this.event_id));
    this.require_filter.index = 1;

    // switch (this.event_type) {
    //   case 'mining':
    //     this.Store = new ScbMembersPointsMining(this.storeService, this.authService, 'edit');
    //     break;
    //   case 'salvage':
    //     this.Store = new ScbMembersPointsSalvage(this.storeService, this.authService, 'edit');
    //     break;
    //   case 'trading':
    //     this.Store = new ScbMembersPointsTrading(this.storeService, this.authService, 'edit');
    //     break;
    // }

    this.Store = new ScbMembersPointsCoins(this.storeService, this.authService, 'edit');

    this.ext_obj = {event_id: this.event_id};
    this.is_loading = false;
    this.cdr.detectChanges();
    this.channelStore = this.storeService.getStore('dc_channel');
    this.channelStore.loadStore('').then((records) => {
      this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
        // refresh table on notifications for user guilds events
        const info = JSON.parse(message.payload.toString());
        const rec = this.channelStore.getRecordById(info.channel_id);
        if (this.Store.getStore() && rec && Number(info.event_id) === Number(this.event_id)) {
          this.is_loading = true;
          this.Store.getStore().reloadStore().then(() => {
            this.is_loading = false;
          });
        }
      }));
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public dataLoadedDone(event) {
    this.totalRecords = event.totalRecords;
    this.dataLoaded.emit(event);
  }

  public createMemberPoints() {
    this.is_loading = true;
    this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() +
            'games/starcitizen/get-from-event/' + this.event_id),
        {}
    ).subscribe(data => {
      this.is_loading = false;
    });
  }
}
