<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="!is_loading && Store">
        <app-fb-forms-table [storeObj]="Store"
                            [edit_allowed]="editAllowed"
                            [require_filter]="require_filter"
                            [storeExtObj]="{event_id: event_id}"
                            [stateKey]="'members-points-details'"
                            [show_add]="false"
                            [show_add_in_view]="editAllowed"
                            [show_delete]="editAllowed"
                            (dataLoaded)="dataLoadedDone($event)"
                            [title]="'Coins'"
        >
        </app-fb-forms-table>
    </div>
</div>
<div>
    <ng-container *ngIf="!is_loading && is_admin && totalRecords === 0">
        <button mat-raised-button class="button-add"
                style="margin-top: -14px;"
                (click)="createMemberPoints()"
                matTooltip="get from event"
                type="button">
            get from event
        </button>
    </ng-container>
</div>